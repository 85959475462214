<template>
	<div class="edit-form-container">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form form-type-2"
			:model="formData"
			:rules="rules"
		>
			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Название дисконта" prop="title">
						<el-input v-model="formData.title" />
					</el-form-item>

					<el-form-item label="Тип дисконта" prop="type">
						<el-select v-model="formData.type" placeholder="выберите тип">
							<el-option
								v-for="item in discountTypesList"
								:key="'discount_type-' + item.id"
								:label="item.title_ru"
								:value="item.id"
							/>
						</el-select>
					</el-form-item>

					<el-form-item label="Значение" prop="value">
						<el-input-number
							v-model="formData.value"
							placeholder="введите значение"
							:min="0"
							:precision="2"
						/>
					</el-form-item>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';

import { required } from '@/constants/validation';
import { itemFormMixin } from '@/mixins';
// import { removeObjProps, getItemValue /*, findItemBy*/ } from '@/helpers';
import { discountTypesList } from '@/constants/global';

export default {
	mixins: [itemFormMixin],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			formData: {
				title: '',
				type: null,
				value: 0
			}
		};
	},

	computed: {
		rules: () => ({
			title: required,
			type: required,
			value: required
		}),

		discountTypesList: () => discountTypesList
	},

	methods: {}
};
</script>
